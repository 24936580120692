import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.sizes.zIndexPageBackground};
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};
`;

const Lights = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: radial-gradient(
      circle at top left,
      ${(props) => props.theme.colors.backgroundLights} -100%,
      ${(props) => props.theme.colors.background} 30%,
      transparent 30%
    ),
    radial-gradient(
      circle at bottom right,
      ${(props) => props.theme.colors.backgroundLights} -100%,
      ${(props) => props.theme.colors.background} 30%,
      transparent 30%
    );
  filter: blur(40px);
`;

function Background() {
  return (
    <Wrapper>
      <Lights />
    </Wrapper>
  );
}

export default Background;
