import styled from "styled-components";
import { Summary as Base } from "@sablier/v2-components/molecules";
import { _ } from "@sablier/v2-mixins";
import type { ComponentProps, MouseEvent } from "react";
import Item, { Value } from "../Item";

const Headers = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    *[data-component="detail"] {
      justify-content: space-between;
      & > * {
        flex: none;
        & > * {
          width: auto;
        }
      }
    }
  }
`;

interface Props {
  calldata?: (e?: MouseEvent<HTMLElement>) => void;
  items: ComponentProps<typeof Item>[];
  headers: ComponentProps<typeof Value>["item"][];
  guide: string | undefined;
}

function Summary({ headers, items, guide }: Props) {
  return (
    <Base.Section free guide={guide}>
      <Headers>
        {headers.map((item, index) => (
          <Value key={index} item={item} />
        ))}
      </Headers>
      <Base.Divider />
      {items.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </Base.Section>
  );
}

export default Summary;
