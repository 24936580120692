import styled from "styled-components";
import { useFT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import type { PropsWithChildren } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: flex-start;
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1) 0;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const Flex = styled.div`
  ${(props) => props.theme.styles.textPlaceholder}
  ${(props) => props.theme.styles.row}
    & {
    flex-wrap: wrap;
    color: ${(props) => props.theme.colors.gray500};
    line-height: 1.4;
    text-align: left;
    & > * {
      display: inline-flex;
    }
    span {
      margin-left: 2px;
      column-gap: 4px;
      cursor: pointer;
      b,
      svg {
        display: inline-flex;
        color: ${(props) => props.theme.colors.secondaryDesaturated};
        column-gap: 2px;
      }
      b {
        position: relative;
        &:after {
          position: absolute;
          bottom: -2px;
          content: "";
          width: 100%;
          height: 2px;
          background-color: ${(props) =>
            props.theme.colors.secondaryDesaturated};
          opacity: 0;
        }

        &:hover,
        &:active {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    ${(props) => props.theme.medias.maxMD} {
      * {
        line-height: 24px;
      }
    }
  }
`;

interface Props {
  className?: string;
  content?: string;
}

export default function Empty({
  className,
  children,
  content: _content,
}: PropsWithChildren<Props>) {
  const content = useFT(_content, "structs.noAssetsFound");
  return (
    <Wrapper className={className}>
      <Content>
        <Flex>{children ? children : <p>{content}</p>}</Flex>
      </Content>
    </Wrapper>
  );
}
