import { useCallback, useEffect, useMemo, useState } from "react";
import { CodeBracketSquareIcon } from "@heroicons/react/24/outline";
import {
  Amount,
  Summary as Base,
  Button,
  Chain,
} from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { IToken } from "@sablier/v2-types";
import { useAccount, useChain } from "~/client/hooks";
import type { ComponentProps, MouseEvent, PropsWithChildren } from "react";
import Item from "../Item";

interface Props {
  calldata?: (e?: MouseEvent<HTMLElement>) => void;
  cancelability?: string;
  transferability?: string;
  items: ComponentProps<typeof Item>[];
  token?: IToken;
  size: number;
}

const PREVIEW = 6;

function Summary({
  calldata,
  cancelability,
  children,
  transferability,
  items,
  token,
  size,
}: PropsWithChildren<Props>) {
  const { isSupported, isMounted } = useAccount();
  const { chain } = useChain();
  const { t } = useT();

  const [showAll, setShowAll] = useState<boolean>(false);

  const [isShowingAll, list] = useMemo(() => {
    if (items.length <= PREVIEW) {
      return [true, items];
    }
    if (!showAll) {
      return [false, items.slice(0, PREVIEW)];
    }
    return [true, items];
  }, [items, showAll]);

  const doShow = useCallback(() => {
    setShowAll(true);
  }, [setShowAll]);

  useEffect(() => {
    setShowAll(false);
  }, [items]);

  return (
    <Base.Section free>
      <Base.Info title={t("words.chain")}>
        {chain && isSupported && isMounted && <Chain id={chain.chainId} />}
      </Base.Info>
      {size >= 2 && (
        <Base.Info title={t("words.grouping")} value={`Group of ${size}`} />
      )}
      <Base.Info title={t("words.cancelability")} value={cancelability} />
      <Base.Info title={t("words.transferability")} value={transferability} />

      <Base.Info title={_.capitalize(t("words.token"))}>
        <Amount
          empty={"dark600"}
          token={token}
          size={18}
          isFormatted={true}
          isFallbackShown={false}
        />
      </Base.Info>

      {!_.isNilOrEmptyString(calldata) ? (
        <Base.Info title={_.capitalize(t("words.calldata"))}>
          <Button
            appearance={"transparent"}
            accent={"gray400"}
            isMini
            isUnpadded
            left={CodeBracketSquareIcon}
            onClick={calldata}
            title={_.capitalize(t("structs.previewCalldata"))}
          />
        </Base.Info>
      ) : (
        false
      )}

      {list.length ? <Base.Divider /> : false}
      {list.map((item, index) => (
        <Item key={index} isInitiallyOpen={index === 0} {...item} />
      ))}
      {!isShowingAll ? (
        <Button
          appearance={"outline"}
          accent={"iconicPassive"}
          title={`${t("structs.showMore")} (+${items.length - PREVIEW})`}
          isMini
          onClick={doShow}
        />
      ) : (
        false
      )}
      {children}
    </Base.Section>
  );
}

export default Summary;
