import { type PropsWithChildren } from "react";
import styled from "styled-components";
import { tags } from "@sablier/v2-constants";
import { Background } from "~/client/components/atoms";
import { Disclaimer, Banner as Terms } from "~/client/components/molecules";
import { Cart } from "../Stream";
import Toolbar from "../Toolbar";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
const Content = styled.div`
  position: relative;
  z-index: ${(props) => props.theme.sizes.zIndexPageContent};
  width: 100%;
  min-height: calc(100vh - ${(props) => props.theme.sizes.toolbar});
`;

function Page({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <Wrapper className={className} data-availability={tags.pageRendered}>
      <Background />
      <Toolbar />
      <Content>{children}</Content>
      <Disclaimer.UK />
      <Terms />
      <Cart />
    </Wrapper>
  );
}

export default Page;
