import { useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  ArrowLeftIcon,
  EllipsisHorizontalIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import AssetCoinbase from "@sablier/v2-assets/wallet/coinbase.png";
import AssetSafe from "@sablier/v2-assets/wallet/safe.svg";
import AssetWalletConnect from "@sablier/v2-assets/wallet/walletConnect.png";
import { Button } from "@sablier/v2-components/molecules";
import { tags } from "@sablier/v2-constants";
import { useShortAddressOrName } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { useAccount, useModalAccount } from "~/client/hooks";

const Wrapper = styled.div`
  ${(props) => props.theme.medias.maxXL} {
    & > *[data-component="button"] {
      *[data-component="side"]:last-child {
        display: none;
      }
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    & > *[data-component="button"] {
      *[data-component="title"],
      *[data-component="side"]:last-child {
        display: none;
      }
    }
  }
`;

function AccountConnected() {
  const { address, connector, ens, isHostSafe } = useAccount();
  const shortAccount = useShortAddressOrName(ens || address, 8);

  const { setOpen } = useModalAccount();

  const openModal = useCallback(
    (val: boolean) => {
      vendors.track.log((events) => {
        return events.openModal("account");
      });
      setOpen(val);
    },
    [setOpen],
  );

  const left = useMemo(() => {
    const o = { leftPurpose: "image" as const } as const;

    if (isHostSafe) {
      return { ...o, left: AssetSafe };
    }

    if (connector?.id === "coinbaseWallet") {
      return { ...o, left: AssetCoinbase };
    }

    if (
      connector?.id === "walletConnect" ||
      connector?.id === "walletConnectLegacy"
    ) {
      return { ...o, left: AssetWalletConnect };
    }

    return {
      left: address,
      leftPurpose: "identicon" as const,
    };
  }, [address, connector, isHostSafe]);

  return (
    <Button
      accent={"dark200"}
      appearance={"solid"}
      cy={tags.auth}
      {...left}
      onClick={() => openModal(true)}
      right={EllipsisHorizontalIcon}
      title={shortAccount}
    />
  );
}

function AccountDisconnected() {
  const { connect } = useAccount();
  const { t } = useT();

  const connectAction = useCallback(() => {
    vendors.track.log("clickOnConnect");
    if (connect) {
      connect();
    }
  }, [connect]);

  return (
    <Button
      accent={"dark200"}
      appearance={"solid"}
      onClick={connectAction}
      left={UserCircleIcon}
      title={t("structs.connectNow")}
    />
  );
}

function AccountConnecting() {
  const { disconnect } = useAccount();
  const { t } = useT();

  return (
    <Button
      accent={"dark200"}
      appearance={"solid"}
      forceLoadingSide={"left"}
      leftPurpose={"empty"}
      left={"dark500"}
      isPreview
      onClick={disconnect}
      right={EllipsisHorizontalIcon}
      title={t("words.connecting")}
    />
  );
}

function AccountUnsure() {
  const { disconnect } = useAccount();
  const { t } = useT();

  return (
    <Button
      accent={"dark200"}
      appearance={"solid"}
      left={ArrowLeftIcon}
      isPreview
      onClick={disconnect}
      title={t("structs.almostThere")}
    />
  );
}

function Account() {
  const { isMounted, status } = useAccount();

  return (
    <Wrapper>
      {isMounted && (
        <>
          {status === "unsure" && <AccountUnsure />}
          {status === "connected" && <AccountConnected />}
          {status === "disconnected" && <AccountDisconnected />}
          {(status === "reconnecting" || status === "connecting") && (
            <AccountConnecting />
          )}
        </>
      )}
    </Wrapper>
  );
}

export default Account;
