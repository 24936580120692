import { useCallback } from "react";
import styled from "styled-components";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { Internal } from "@sablier/v2-components/atoms";
import { Button } from "@sablier/v2-components/molecules";
import { useChainData } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import {
  useAccount,
  useMachineFaucet,
  useModalTransaction,
  useResetter,
  useToken,
} from "~/client/hooks";

const WrapperPartial = styled(Internal)`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    justify-content: center;
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1);
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark200};

    cursor: pointer !important;
    overflow: hidden;

    &:not(:first-child) {
      margin-top: calc(${(props) => props.theme.sizes.edge} * -1);
    }
    * {
      cursor: pointer !important;
    }
  }
`;

const Description = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    gap: calc(${(props) => props.theme.sizes.edge} * 2 / 3);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: ${(props) => props.theme.colors.gray400};
  }
`;

const Wrapper = styled(WrapperPartial)`
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.dark300};
  }

  ${(props) => props.theme.medias.maxLG} {
    padding: calc(${(props) => props.theme.sizes.edge});
  }

  ${(props) => props.theme.medias.maxXS} {
    padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

function Faucet() {
  const { t } = useT();
  const { chainId, isConfigured, library, signer } = useAccount();
  const { chain } = useChainData(chainId);
  const { doClose, setOpen, updateData } = useModalTransaction();
  const { token: reset } = useResetter();
  const token = useToken({ address: chain?.faucet, chainId });

  const { send } = useMachineFaucet();

  const doFaucet = useCallback(() => {
    send({
      type: "SAVE",
      payload: {
        api: {
          doClose,
          reset,
          setOpen,
          t,
          updateData,
        },
        library,
        signer,
        token,
      },
    });
  }, [doClose, library, reset, send, setOpen, signer, t, token, updateData]);

  if (_.isNilOrEmptyString(token) || !isConfigured) {
    return false;
  }

  return (
    <Wrapper onClick={doFaucet}>
      <Description>
        <Button
          appearance={"transparent"}
          accent={"gray100"}
          isPreview
          isUnpadded
          title={t("structs.getTestnet", { token: token?.symbol || "Tokens" })}
        />
        <Button
          appearance={"solid"}
          accent={"white"}
          isMini
          left={BanknotesIcon}
          title={_.capitalize(t("words.faucet"))}
        />
      </Description>
    </Wrapper>
  );
}

export default Faucet;
