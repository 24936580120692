import styled from "styled-components";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { useAccount, useModalPreflight } from "~/client/hooks";

const Wrapper = styled.div`
  & > * {
    border-radius: calc(${(props) => props.theme.sizes.button} * 1 / 2);
  }
`;

function Preflight() {
  const { t } = useT();
  const { isConnected, isConfigured, isReady, isHostSafe, isHostCypress } =
    useAccount();
  const { doOpen } = useModalPreflight();

  if (!isReady || isConfigured || isConnected || isHostSafe || isHostCypress) {
    return false;
  }

  return (
    <Wrapper>
      <Button
        accent={"dark200"}
        appearance={"solid"}
        title={""}
        right={EllipsisHorizontalIcon}
        onClick={doOpen}
        tooltip={{ value: _.capitalize(t("words.settings")) }}
      />
    </Wrapper>
  );
}

export default Preflight;
