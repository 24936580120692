import { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "@sablier/v2-assets/logo/long-gradient.svg";
import { Internal } from "@sablier/v2-components/atoms";
import { routes } from "@sablier/v2-constants";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import Account from "./Account";
import Chain from "./Chain";
import Navigation from "./Navigation";
import Preflight from "./Preflight";

const WrapperPartial = styled.nav`
  position: relative;
  z-index: ${(props) => props.theme.sizes.zIndexToolbar};
  width: 100%;
  height: ${(props) => props.theme.sizes.toolbar};
  border-bottom: 2px solid ${(props) => props.theme.colors.border};

  &[data-home="true"] {
    border-bottom: 2px solid ${(props) => props.theme.colors.dark250};
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  ${(props) => props.theme.styles.row}

  & {
    height: 100%;
  }
`;

const Branding = styled(Internal)`
  width: 150px;
  height: 100%;
  margin-right: auto;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Menu = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    height: 100%;
  }

  & > *:not(:last-child) {
    margin-right: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    ${Branding} {
      flex-shrink: 0;
      width: 140px;
    }
    ${Menu} {
      & > *:not(:last-child) {
        margin-right: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Branding} {
      flex-shrink: 0;
      width: 120px;
    }
  }
`;

function Toolbar() {
  const router = useRouter();

  const isHome = useMemo(
    () => router.pathname === routes.client.pages.dashboard.route,
    [router],
  );

  return (
    <Wrapper data-home={isHome}>
      <Container>
        <Branding
          onClick={() => {
            vendors.track.log("clickLogo");
          }}
          to={routes.client.pages.dashboard.builder()}
        >
          <Logo />
        </Branding>
        <Menu>
          <Navigation />
          <Chain />
          <Account />
          <Preflight />
        </Menu>
      </Container>
    </Wrapper>
  );
}

export default Toolbar;
