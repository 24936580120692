import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import {
  ArrowDownCircleIcon,
  ArrowLeftIcon,
  CubeIcon,
  InboxArrowDownIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { routes } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { useRouter } from "next/router";
import { useAccount, useExpected, useModalExpected } from "~/client/hooks";

const fadeToRight = css`
  position: absolute;
  left: 0;
  content: "";
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.dark600} 0%,
    transparent 70%
  );
`;

const fadeToLeft = css`
  ${fadeToRight}
  & {
    left: inherit;
    right: 0;
    background: linear-gradient(
      270deg,
      ${(props) => props.theme.colors.dark600} 0%,
      transparent 70%
    );
  }
`;

const Tabs = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    & > * {
      position: relative;

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 4px;
        &[data-preview="true"] {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          background: ${(props) => props.theme.colors.primary10};

          &:after {
            ${(props) => props.theme.styles.shinny}
            & {
              --highlight: ${(props) => props.theme.colors.white30};
              --end: ${(props) => props.theme.colors.primaryMiddle};
              --top: -2px;
              left: 8px;
              width: 50%;
            }
          }
        }
        &:not([data-preview="true"]) {
          &:before {
            ${fadeToLeft}
            & {
              top: 0;
            }
          }

          &:after {
            ${fadeToLeft}
            & {
              bottom: 0;
            }
          }
          & > *:first-child {
            &:before {
              content: "";
              width: 2px;
              height: 2px;
            }
          }
        }
      }

      &:last-child {
        border-top-left-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0px;
        &[data-preview="true"] {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          background: ${(props) => props.theme.colors.purple10};

          &:after {
            ${(props) => props.theme.styles.shinny}
            & {
              --highlight: ${(props) => props.theme.colors.white30};
              --end: ${(props) => props.theme.colors.purple};
              --bottom: -2px;
              right: 8px;
              width: 50%;
            }
          }
        }
        &:not([data-preview="true"]) {
          &:before {
            ${fadeToRight}
            & {
              top: 0;
            }
          }

          &:after {
            ${fadeToRight}
            & {
              bottom: 0;
            }
          }
          & > *:last-child {
            &:after {
              content: "";
              display: inline-flex;
              width: 2px;
              height: 2px;
            }
          }
        }
      }
    }
  }
`;

const WrapperExpected = styled.div``;

const WrapperEntities = styled.div``;

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }

  ${(props) => props.theme.medias.maxLG} {
    & > div:not(${WrapperExpected}):not(${WrapperEntities}) {
      display: none;
    }
    ${WrapperExpected} {
      display: none;
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${WrapperEntities} {
      ${Tabs} {
        display: none;
      }
    }
  }
`;

function Entities() {
  const { t } = useT();
  const router = useRouter();

  const isAirstream = useMemo(
    () =>
      Object.values(routes.client.pages.airstream)
        .map((item) => item.route)
        .includes(router.pathname),
    [router],
  );

  return (
    <WrapperEntities>
      <Tabs>
        <Button
          accent={!isAirstream ? "primaryNeon" : "iconicPassive"}
          appearance={!isAirstream ? "outline" : "solid"}
          left={ArrowDownCircleIcon}
          purpose={"internal"}
          isPreview={!isAirstream}
          title={_.capitalize(t("words.streams"))}
          titleMedium={"S"}
          to={routes.client.pages.dashboard.builder()}
        />
        <Button
          accent={isAirstream ? "purpleNeon" : "iconicPassive"}
          appearance={isAirstream ? "outline" : "solid"}
          isPreview={isAirstream}
          left={CubeIcon}
          purpose={"internal"}
          title={_.capitalize(t("words.airstreams"))}
          titleMedium={"A"}
          to={routes.client.pages.airstream.dashboard.builder()}
        />
      </Tabs>
    </WrapperEntities>
  );
}

function Expected() {
  const { t } = useT();

  const { isConnected, isConfigured } = useAccount();
  const { doOpen: doOpenExpected } = useModalExpected();
  const { configured: list } = useExpected();

  const isExpected = useMemo(
    () => isConfigured && list.length !== 0,
    [isConfigured, list],
  );

  const openExpected = useCallback(() => {
    vendors.track.log((events) => events.openModal("expected"));
    doOpenExpected();
  }, [doOpenExpected]);

  if (!isConnected) {
    return false;
  }

  return (
    <WrapperExpected>
      {isExpected ? (
        <Button
          accent={"red"}
          appearance={"gradient"}
          left={InboxArrowDownIcon}
          onClick={openExpected}
          isDisabled={!isConfigured}
          purpose={"button"}
          tooltip={{
            color: "dark250",
            value: t("structs.expectedStreams"),
          }}
        />
      ) : (
        <Button
          accent={"iconicPassive"}
          appearance={"solid"}
          left={InboxArrowDownIcon}
          onClick={openExpected}
          isDisabled={!isConfigured}
          purpose={"button"}
          tooltip={{
            color: "dark250",
            value: t("structs.expectedStreams"),
          }}
        />
      )}
    </WrapperExpected>
  );
}

function Navigation() {
  const router = useRouter();

  const { t } = useT();

  const Specific = useCallback(() => {
    const back = (() => {
      switch (router.pathname) {
        case routes.client.pages.createGroup.route:
          return routes.client.pages.gallery.builder();
        case routes.client.pages.airstream.dashboard.route:
        case routes.client.pages.gallery.route:
          return routes.client.pages.dashboard.builder();
        case routes.client.pages.airstream.create.route:
        case routes.client.pages.airstream.profile.route:
          return routes.client.pages.airstream.dashboard.builder();
        default:
          return undefined;
      }
    })();

    if (!_.isNilOrEmptyString(back)) {
      return (
        <Button
          accent={"dark200"}
          appearance={"solid"}
          left={ArrowLeftIcon}
          title={""}
          purpose={"internal"}
          to={back}
          tooltip={{
            color: "dark250",
            value: t("structs.goBack"),
          }}
        />
      );
    }
  }, [router, t]);

  return (
    <Wrapper>
      <Specific />
      <Expected />
      <Entities />
    </Wrapper>
  );
}

export default Navigation;
