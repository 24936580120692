import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ClockIcon, ShieldCheckIcon } from "@heroicons/react/24/outline";
import { Button, Warning } from "@sablier/v2-components/molecules";
import { tags } from "@sablier/v2-constants";
import { Trans, useT } from "@sablier/v2-locales";
import { policy } from "@sablier/v2-machines";
import { _ } from "@sablier/v2-mixins";
import { vendors } from "@sablier/v2-utils";
import { Card } from "~/client/components/molecules";
import {
  useAccount,
  useStreamCreateGroupField,
  useTokenAllowance,
} from "~/client/hooks";

const Wrapper = styled(Card)``;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    & > * {
      width: 100%;
    }

    *[data-component="button"] {
      position: relative;
      &:after {
        position: absolute;
        left: 12px;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${(props) => props.theme.colors.dark400};
      }
    }
  }
`;

const About = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      color: ${(props) => props.theme.colors.gray400};
      line-height: 160%;
    }
  }
`;

interface Props {
  allowance: ReturnType<typeof useTokenAllowance>;
  purpose: "group" | "single";
}

function Approval({ allowance, purpose }: Props) {
  const { isConfigured } = useAccount();
  const token = useStreamCreateGroupField("token");
  const { t } = useT();

  const approve = useCallback(() => {
    vendors.track.log(
      purpose === "single" ? "singleAllowance" : "groupAllowance",
    );
    allowance.doApprove();
  }, [allowance, purpose]);

  const error = useMemo(
    () =>
      !_.isNilOrEmptyString(allowance.error)
        ? policy.error.message(t, allowance.error)
        : undefined,
    [allowance, t],
  );

  const condition = useMemo(() => {
    if (_.isNilOrEmptyString(token.field.value) || token.field.warning) {
      return false;
    }

    if (
      allowance.debounced === "allowed" ||
      allowance.debounced === "idle" ||
      !isConfigured
    ) {
      return false;
    }

    return true;
  }, [allowance, isConfigured, token.field]);

  if (!condition) {
    return false;
  }

  return (
    <Wrapper isTopHidden>
      <Content>
        <About>
          <p>
            <Trans
              i18nKey={`machines.allowance.${purpose}`}
              components={[<b key={"0"} />]}
              values={{ symbol: token.field.value?.symbol }}
            />
          </p>
        </About>
        <Button
          accent={"white"}
          appearance={"solid"}
          onClick={approve}
          isLoading={allowance.isApproving}
          right={ShieldCheckIcon}
          left={ClockIcon}
          title={_.capitalize(t("words.allow"))}
        />
        <Warning cy={tags.warning} value={error} />
      </Content>
    </Wrapper>
  );
}

export default Approval;
